import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { MenuMode, NavigationService } from '../../services/navigation-service/navigation.service';
import { EventPermission } from 'projects/api-client/src/models/common/EventPermission';
import { AuthService, EventAPIService } from 'projects/api-client/src/public-api';
import { Organization } from '../../models/organization/organization';
import { OrganizationPermission } from 'projects/api-client/src/models/common/OrganizationPermission';
import { CONSTANTS } from 'src/environments/constants';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MoreMenuDialogComponent, MoreMenuDialogData } from '../more-menu-dialog/more-menu-dialog.component';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, Scroll } from '@angular/router';
import { Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Event } from '../../models/event/event';
import { ApplicationState } from 'projects/api-client/src/models/common/ApplicationState';
import { ApplicationPermission } from 'projects/api-client/src/models/common/ApplicationPermission';
import { ClientSettingsService } from '../../services/client-settings-service/client-settings.service';
import { EventContextService } from 'src/app/pages/event/event-context.service';
import { TrialService } from '../../services/trial-service/trial.service';

@Component({
    selector: 'main-layout',
    templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent implements OnDestroy {

    CONSTANTS = CONSTANTS;
    MAX_PRIMARY_MOBILE_BUTTONS = 5;
    NavigationService = NavigationService;
    EventPermission = EventPermission;
    OrganizationPermission = OrganizationPermission;

    currentEvent?: Event;
    currentOrganization?: Organization;
    userEvents: Event[] = [];

    VM?: ViewModel;

    MenuMode = MenuMode;
    currentMenuMode: MenuMode = MenuMode.EMPTY;
    showMobileMenu: boolean = true;
    showLayout: boolean = true;
    DesktopMoreMenuVisibility = DesktopMoreMenuVisibility;
    desktopMoreMenuVisibility: DesktopMoreMenuVisibility = DesktopMoreMenuVisibility.CLOSED;

    hideFooter: boolean = false;

    navigationOpened: boolean = true;

    helpCenterUrl?: string;

    private _unsubscribeAll = new Subject<any>();

    private rootRoute = (route: ActivatedRoute): ActivatedRoute => {
        return route.firstChild ? this.rootRoute(route.firstChild) : route;
    }

    constructor(activatedRoute: ActivatedRoute,
        private navigationService: NavigationService,
        private authService: AuthService,
        private eventAPIService: EventAPIService,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        clientSettingsService: ClientSettingsService,
        private eventContextService: EventContextService,
        private renderer: Renderer2,
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        public trialService: TrialService) {

        router.events
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter((event) => event instanceof NavigationEnd  || event instanceof Scroll),
                mergeMap(() => this.rootRoute(this.router.routerState.root).data),
                map(({ hideFooter }: any) => { return { hideFooter } })
            ).subscribe(({ hideFooter }) => {
                this.hideFooter = hideFooter === true;
            });

        // Initialize navigation opened
        this.navigationOpened = this.navigationService.UserSettings?.navigationOpened || true;

        this.navigationService.menuChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ menuMode, currentEvent, currentOrganization }) => {
                this.currentMenuMode = menuMode;
                this.currentEvent = currentEvent;
                this.currentOrganization = currentOrganization;
                this.buildMobileLinks();
            });

        this.navigationService.showMobileMenuChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((showMobileMenu) => {
                this.showMobileMenu = showMobileMenu;
            });

        this.navigationService.showLayoutChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(showLayout => {
                this.showLayout = showLayout;
            });

        this.authService.currentUserContextChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(async userContext => {
                if (userContext) {
                    const events = await this.eventAPIService.listEventsByUser().toPromise();
                    this.userEvents = events.map(x => Event.from(x)).filter(x => x.user_application_state === ApplicationState.ACCEPTED && !x.is_archived);
                    this.VM = {
                        last_name: userContext.last_name,
                        first_name: userContext.first_name,
                        email: userContext.email,
                        //photo_url: userContext.photo_url,
                        isAdmin: userContext.permissions.includes(ApplicationPermission.ADMIN_ACCESS),
                    }
                    this.buildMobileLinks();
                } else {
                    this.VM = undefined;
                }
            });

        this.router.events
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter((event) => event instanceof NavigationStart)
            )
            .subscribe(() => {
                if (this.mobileMoreMenuDialogRef) {
                    this.mobileMoreMenuDialogRef.close();
                }
                this.desktopMoreMenuVisibility = DesktopMoreMenuVisibility.CLOSED;
                this.navigationService.showMobileMenu = true;
            });

        this.breakpointObserver.observe([Breakpoints.XSmall])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(state => {
                // Close "more" menus when display changes (ex: orientation)
                if (state.breakpoints[Breakpoints.XSmall]) this.desktopMoreMenuVisibility = DesktopMoreMenuVisibility.CLOSED;
                else if (this.mobileMoreMenuDialogRef) this.mobileMoreMenuDialogRef.close();
            });

        this.helpCenterUrl = clientSettingsService.getValueInLocale(clientSettingsService.clientSettings?.help_center_url);
    }

    toggleNavigationOpened() {
        this.navigationOpened = !this.navigationOpened;
        this.navigationService.updateUserSettings({
            navigationOpened: this.navigationOpened
        });
    }

    mobileLinks: MoreMenuDialogData = { groups: [] };

    buildMobileLinks() {

        this.mobileLinks = { groups: [] };

        if (this.currentMenuMode === MenuMode.ADMIN) {
            const primaryLinks: MenuLink[] = [];
            primaryLinks.push(
                {
                    title: $localize`Events`,
                    icon: "event_note",
                    url: NavigationService.AdminRoutes.EventList(),
                    isExternalUrl: false
                },
                {
                    title: $localize`Organizations`,
                    icon: "business",
                    url: NavigationService.AdminRoutes.OrganizationList(),
                    isExternalUrl: false
                },
                {
                    title: $localize`Users`,
                    icon: "people",
                    url: NavigationService.AdminRoutes.UserList(),
                    isExternalUrl: false
                }
            );
            this.mobileLinks.groups.push({
                links: primaryLinks
            });
        }

        if (this.currentMenuMode === MenuMode.EVENT) {

            const primaryLinksTeammate: MenuLink[] = [];
            const primaryLinksAdmin: MenuLink[] = [];
            const secondaryLinksAdmin: MenuLink[] = [];
            const tertiaryLinksAdmin: MenuLink[] = [];

            if (this.currentEvent!.user_event_permissions.includes(EventPermission.TEAMMATE_ACCESS)) {

                // Primary teammate links
                primaryLinksTeammate.push({
                    title: $localize`Home`,
                    icon: "home",
                    url: NavigationService.EventRoutes.Teammate.Home(this.currentEvent!.id),
                    isExternalUrl: false
                });
                if (this.currentEvent!.user_event_permissions.includes(EventPermission.TEAMMATE_PLANNING_READ)) {
                    primaryLinksTeammate.push({
                        title: $localize`My schedule`,
                        icon: "event",
                        url: NavigationService.EventRoutes.Teammate.Activities(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                }
                if (this.currentEvent!.user_event_permissions.includes(EventPermission.TEAMMATE_MAP_READ)) {
                    primaryLinksTeammate.push({
                        title: $localize`Map`,
                        icon: "location_on",
                        url: NavigationService.EventRoutes.Teammate.Map(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                }
                if (this.currentEvent!.user_event_permissions.includes(EventPermission.TEAMMATE_COURSES_READ)) {
                    primaryLinksTeammate.push({
                        title: $localize`Training courses`,
                        icon: "school",
                        url: NavigationService.EventRoutes.Teammate.Courses(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                }
                primaryLinksTeammate.push({
                    title: $localize`Documents`,
                    icon: "file_present",
                    url: NavigationService.EventRoutes.Teammate.Attachments(this.currentEvent!.id),
                    isExternalUrl: false
                });
                primaryLinksTeammate.push({
                    title: $localize`My profile`,
                    icon: "account_circle",
                    url: NavigationService.EventRoutes.Teammate.Profile(this.currentEvent!.id),
                    isExternalUrl: false
                });
            }

            if (this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_ACCESS)) {

                // Primary admin links
                primaryLinksAdmin.push({
                    title: $localize`Dashboard`,
                    icon: "dashboard",
                    url: NavigationService.EventRoutes.Admin.Dashboard(this.currentEvent!.id),
                    isExternalUrl: false
                });
                primaryLinksAdmin.push({
                    title: $localize`Contacts`,
                    icon: "people_alt",
                    url: NavigationService.EventRoutes.Admin.Teammates(this.currentEvent!.id),
                    isExternalUrl: false
                });
                primaryLinksAdmin.push({
                    title: $localize`Activities`,
                    icon: "date_range",
                    url: NavigationService.EventRoutes.Admin.Activities(this.currentEvent!.id),
                    isExternalUrl: false
                });

                // Secondary admin links
                if (this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_MATERIAL_WRITE)
                    || this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_MATERIAL_READ)) {
                    secondaryLinksAdmin.push({
                        title: $localize`Material`,
                        icon: "shopping_bag",
                        url: NavigationService.EventRoutes.Admin.MaterialItems(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                }
                if (this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_MEALS_WRITE)
                    || this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_MEALS_READ)) {
                    secondaryLinksAdmin.push({
                        title: $localize`Catering`,
                        icon: "restaurant",
                        url: NavigationService.EventRoutes.Admin.Meals(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                }
                if (this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_MAP_WRITE)
                    || this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_MAP_READ)) {
                    secondaryLinksAdmin.push({
                        title: $localize`Information map`,
                        icon: "pin_drop",
                        url: NavigationService.EventRoutes.Admin.MapEditor(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                }
                if (this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_EVENT_WRITE)
                    || this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_EVENT_READ)) {
                    secondaryLinksAdmin.push({
                        title: $localize`Registration form`,
                        icon: "list_alt",
                        url: NavigationService.EventRoutes.Admin.FormDetails(this.currentEvent!.id, 'application'),
                        isExternalUrl: false
                    });
                }
                if (this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_EVENT_WRITE)
                    || this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_EVENT_READ)) {
                    secondaryLinksAdmin.push({
                        title: $localize`Forms`,
                        icon: "quiz",
                        url: NavigationService.EventRoutes.Admin.Forms(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                }
                if (this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_COURSES_WRITE)
                    || this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_COURSES_READ)) {
                    secondaryLinksAdmin.push({
                        title: $localize`Training courses`,
                        icon: "school",
                        url: NavigationService.EventRoutes.Admin.Courses(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                }
                if (this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_MESSAGES_READ)) {
                    secondaryLinksAdmin.push({
                        title: $localize`Messaging`,
                        icon: "forum",
                        url: NavigationService.EventRoutes.Admin.TeammateMessaging(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                }

                // Tertiary admin links
                if (this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_EVENT_WRITE)
                    || this.currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_EVENT_READ)) {
                    tertiaryLinksAdmin.push({
                        title: $localize`Event`,
                        icon: "settings",
                        url: NavigationService.EventRoutes.Admin.EventSettings(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                    tertiaryLinksAdmin.push({
                        title: $localize`Contact information`,
                        icon: "group",
                        url: NavigationService.EventRoutes.Admin.TeammateFields(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                    tertiaryLinksAdmin.push({
                        title: $localize`Documents`,
                        icon: "file_present",
                        url: NavigationService.EventRoutes.Admin.Attachments(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                    tertiaryLinksAdmin.push({
                        title: $localize`Badges`,
                        icon: "badge",
                        url: NavigationService.EventRoutes.Admin.Badges(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                    tertiaryLinksAdmin.push({
                        title: $localize`Publication`,
                        icon: "web",
                        url: NavigationService.EventRoutes.Admin.PublicationSettings(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                    tertiaryLinksAdmin.push({
                        title: $localize`Users`,
                        icon: "admin_panel_settings",
                        url: NavigationService.EventRoutes.Admin.Membership(this.currentEvent!.id),
                        isExternalUrl: false
                    });
                    if (this.VM?.isAdmin) {
                        tertiaryLinksAdmin.push({
                            title: $localize`Integrations`,
                            icon: "extension",
                            url: NavigationService.EventRoutes.Admin.Integration(this.currentEvent!.id),
                            isExternalUrl: false
                        });
                    }
                }

                // Bottom link
                this.mobileLinks.bottomLink = {
                    title: $localize`Scan QR-Code`,
                    icon: "qr_code_scanner",
                    url: NavigationService.EventRoutes.Admin.QrCodeScan(this.currentEvent!.id),
                    isExternalUrl: false
                }
            }

            if (primaryLinksTeammate.length > 0) {
                this.mobileLinks.groups.push({
                    links: primaryLinksTeammate
                });
            }
            if (primaryLinksAdmin.length > 0) {
                this.mobileLinks.groups.push({
                    links: primaryLinksAdmin
                });
            }
            if (secondaryLinksAdmin.length > 0) {
                this.mobileLinks.groups.push({
                    links: secondaryLinksAdmin
                });
            }
            if (tertiaryLinksAdmin.length > 0) {
                this.mobileLinks.groups.push({
                    links: tertiaryLinksAdmin
                });
            }
        }

        if (this.currentMenuMode === MenuMode.ORGANIZATION) {
            const primaryLinks: MenuLink[] = [];
            primaryLinks.push({
                title: $localize`Home`,
                icon: "home",
                url: NavigationService.OrganizationRoutes.Home(this.currentOrganization!.id),
                isExternalUrl: false
            });
            primaryLinks.push({
                title: $localize`Events`,
                icon: "event_available",
                url: NavigationService.OrganizationRoutes.Events(this.currentOrganization!.id),
                isExternalUrl: false
            });
            primaryLinks.push({
                title: $localize`Community`,
                icon: "people",
                url: NavigationService.OrganizationRoutes.OrganizationTeammates(this.currentOrganization!.id),
                isExternalUrl: false
            });
            primaryLinks.push({
                title: $localize`Messaging`,
                icon: "forum",
                url: NavigationService.OrganizationRoutes.TeammateMessaging(this.currentOrganization!.id),
                isExternalUrl: false
            });
            primaryLinks.push({
                title: $localize`Training library`,
                icon: "school",
                url: NavigationService.OrganizationRoutes.CourseList(this.currentOrganization!.id),
                isExternalUrl: false
            });
            primaryLinks.push({
                title: $localize`Stats`,
                icon: "bar_chart",
                url: NavigationService.OrganizationRoutes.Stats(this.currentOrganization!.id),
                isExternalUrl: false
            });

            const secondaryLinks: MenuLink[] = [];
            if (this.currentOrganization!.user_organization_permissions.includes(OrganizationPermission.ADMIN_ORGANIZATION_MANAGE)) {

                secondaryLinks.push({
                    title: $localize`Organization`,
                    icon: "business",
                    url: NavigationService.OrganizationRoutes.Settings(this.currentOrganization!.id),
                    isExternalUrl: false
                });
                secondaryLinks.push({
                    title: $localize`Users`,
                    icon: "admin_panel_settings",
                    url: NavigationService.OrganizationRoutes.UserList(this.currentOrganization!.id),
                    isExternalUrl: false
                });
                secondaryLinks.push({
                    title: $localize`Shared fields library`,
                    icon: "storage",
                    url: NavigationService.OrganizationRoutes.SharedFieldsLibrary(this.currentOrganization!.id),
                    isExternalUrl: false
                });
                secondaryLinks.push({
                    title: $localize`Affiliation`,
                    icon: "hub",
                    url: NavigationService.OrganizationRoutes.Affiliation(this.currentOrganization!.id),
                    isExternalUrl: false
                });
                secondaryLinks.push({
                    title: $localize`Subscription`,
                    icon: "contacts",
                    url: NavigationService.OrganizationRoutes.Subscription(this.currentOrganization!.id),
                    isExternalUrl: false
                });
            }

            this.mobileLinks.groups.push({
                links: primaryLinks
            }, {
                links: secondaryLinks
            });
        }

        // Handle the primary links limitation
        let primaryButtonLimit = this.MAX_PRIMARY_MOBILE_BUTTONS;
        if (this.mobileLinks.groups.length > 1) primaryButtonLimit--; // need to reserve 1 button for the more menu
        if (this.mobileLinks.groups[0]?.links.length > primaryButtonLimit) {
            // Split the first group in 2 groups
            const primaryLinks = this.mobileLinks.groups[0].links;
            this.mobileLinks.groups = this.mobileLinks.groups.slice(1);
            this.mobileLinks.groups.unshift({
                links: primaryLinks.slice(0, this.MAX_PRIMARY_MOBILE_BUTTONS - 1)
            }, {
                links: primaryLinks.slice(this.MAX_PRIMARY_MOBILE_BUTTONS - 1)
            });
        }
    }

    mobileMoreMenuDialogRef?: MatDialogRef<MoreMenuDialogComponent>;

    toggleMobileMoreMenu() {
        if (!this.mobileMoreMenuDialogRef) {
            const links: MoreMenuDialogData = {
                groups: this.mobileLinks.groups.slice(1),
                bottomLink: this.mobileLinks.bottomLink
            };
            this.renderer.addClass(this.document.documentElement, 'qo-PersistentMobileNav');
            this.mobileMoreMenuDialogRef = this.dialog.open(MoreMenuDialogComponent, {
                width: "100%", height: "100%", panelClass: "qo-DialogFullScreen", closeOnNavigation: true, hasBackdrop: false,
                data: links
            });
            this.mobileMoreMenuDialogRef.afterClosed().subscribe(() => {
                this.mobileMoreMenuDialogRef = undefined;
                this.renderer.removeClass(this.document.documentElement, 'qo-PersistentMobileNav');
            });
        } else {
            this.mobileMoreMenuDialogRef.close();
        }
    }

    getNavTooltip() {
        return this.navigationOpened ? $localize`Close menu` : $localize`Menu`;
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

interface ViewModel {
    last_name: string;
    first_name: string;
    email: string;
    photo_url?: string;
    isAdmin: boolean;
}

enum DesktopMoreMenuVisibility {
    OPEN_ROLLOVER,
    OPEN_CLICK,
    CLOSED
}

export interface MenuLink {
    icon: string;
    url: any[] | string;
    isExternalUrl: boolean;
    title: string;
}
