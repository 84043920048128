import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { skip, debounceTime, map, skipWhile } from 'rxjs/operators';
import { Filter } from './filters/filter';
import { default as Fuse } from 'fuse.js';
import * as _ from 'lodash';
import { SortItem } from './SortItem';

export class DataService<T extends Entity> {

    // Data
    protected datasourceBS = new BehaviorSubject<T[]>([]);
    public $datasource = this.datasourceBS.asObservable();
    public get datasource(): T[] { return this.datasourceBS.getValue(); }

    public updateDatasource(data: T[]) {
        this.datasourceBS.next(data);
    }
    
    private _lastRefreshed?: Date;
    public get lastRefreshed(): Date | undefined {
        return this._lastRefreshed;
    };

    // Filtered data
    protected filteredDatasourceBS = new BehaviorSubject<T[]>([]);
    public $filteredDatasource = this.filteredDatasourceBS.asObservable();
    public get filteredDatasource(): T[] { return this.filteredDatasourceBS.getValue(); }

    // Filters
    public get filters(): Filter<T>[] {
        return this.configuration.filters;
    }
    public set filters(value: Filter<T>[]) {
        this.configuration.filters = value;
        this.onFilterChange();
    }

    // Sorting
    public get sortItems(): SortItem<T>[] {
        return this.configuration.sortItems ?? [];
    }
    public set sortItems(value: SortItem<T>[]) {
        this.configuration.sortItems = value;
        this.onSortChange();
    }

    // SearchKeys
    public get searchKeys(): Array<Fuse.FuseOptionKey<T>> {
        return this.configuration.searchKeys;
    }
    public set searchKeys(value: Array<Fuse.FuseOptionKey<T>>) {
        this.configuration.searchKeys = value;
    }

    // Search text
    protected searchTextBS = new BehaviorSubject<string>("");
    public $searchText = this.searchTextBS.asObservable();
    public get searchText(): string { return this.searchTextBS.getValue(); }
    public set searchText(text: string) {
        if (this.searchTextBS.getValue() !== text)
            this.searchTextBS.next(text);
    }

    // Selection
    protected selectedIdsBS = new BehaviorSubject<string[]>([]);
    public $selectedIds = this.selectedIdsBS.asObservable();
    public get selectedIds() { return this.selectedIdsBS.value; };
    public onSelectionChanged: Observable<T[]> = this.selectedIdsBS.asObservable()
        .pipe(map(ids => {
            return ids.map(id => {
                const item = this.datasourceBS.getValue().find(x => x.id === id)!;
                return item;
            });
        }));
        
    protected isLoadingBS = new BehaviorSubject<boolean>(false);
    public $isLoading = this.isLoadingBS.asObservable();

    constructor(protected loadEntities: () => Promise<T[]>, private configuration: DataServiceConfiguration<T>) {

        // Process filtered entities when the datasource or the filter conditions changed
        combineLatest([
            this.$datasource,
            this.$searchText
        ]).pipe(
            skip(1), 
            skipWhile(() => this.isLoadingEntities.value === true),
            debounceTime(300)
        ).subscribe(async () => {
            await this.processEntities();
        });
    }

    private isLoadingEntities = new BehaviorSubject<boolean>(false);

    public async refresh() {
        if(!this.isLoadingBS.value) this.isLoadingBS.next(true);
        if(!this.isLoadingEntities.value) this.isLoadingEntities.next(true);
        const entities = await this.loadEntities();
        this.isLoadingBS.next(false);
        this.isLoadingEntities.next(false);
        this.datasourceBS.next(entities);
    }

    public onFilterChange() {
        this.processEntities();
    }

    public clearFilters() {
        for (const filter of this.configuration.filters) {
            filter.clear();
        };
        this.onFilterChange();
    }

    public async clearFiltersAndSearch() {
        this.clearFilters();
        this.searchText = "";
    }

    public onSortChange() {
        this.processEntities();
    }

    private async processEntities(entities: T[] = this.datasourceBS.getValue()) {
        let processedEntities = entities.slice();

        // Refresh filter facets
        for (let filter of this.configuration.filters) {
            if (filter.updateFilter) {
                filter.updateFilter(processedEntities);
            }
        }

        // Apply filters
        for (let filter of this.configuration.filters) {
            if (filter.isActive()) {
                processedEntities = filter.apply(processedEntities, filter.value);
            }
        }

        // Apply sorting
        if (this.configuration.sortItems) {
            for (let sort of this.configuration.sortItems) {
                if (sort.isActive === true) {
                    processedEntities = sort.apply(processedEntities, sort.keyName, sort.direction);
                }
            }
        }

        // Apply search
        if (this.searchText?.length > 0) {
            processedEntities = this.search(processedEntities, this.searchText);
        }

        // Remove items that have been filtered out from the selection
        const filteredIds = processedEntities.map(x => x.id);
        let filteredSelectedIds: string[] = [];
        for (const selectedId of this.selectedIdsBS.value) {
            if (filteredIds.includes(selectedId)) {
                filteredSelectedIds.push(selectedId);
            }
        };
        if (filteredSelectedIds.length < this.selectedIdsBS.value.length) {
            this.selectedIdsBS.next(filteredSelectedIds);
        }

        this.filteredDatasourceBS.next(processedEntities);
    }

    public getSelectedEntities(): T[] {
        return this.datasourceBS.value.filter(x => this.selectedIdsBS.value.includes(x.id));
    }

    public toggleSelection(id?: string) {
        const filteredItems = this.filteredDatasourceBS.value;
        if (filteredItems.length > 0) {
            let selectedIds = this.selectedIdsBS.value;
            if (id === undefined) {
                // Toggle selection for all items
                this.selectedIdsBS.next(selectedIds.length === filteredItems.length ? [] : filteredItems.map(x => x.id));
            } else {
                // Toggle selection for the given item
                const item = filteredItems.find(x => x.id === id);
                if (item) {
                    const isSelected = selectedIds.includes(item.id);
                    if (isSelected) selectedIds = selectedIds.filter(x => x != item.id);
                    else selectedIds.push(item.id);
                    this.selectedIdsBS.next(selectedIds);
                }
            }
        }
    }

    public selectAll() {
        this.selectedIdsBS.next(this.filteredDatasource.map(x => x.id));
    }

    public unselectAll() {
        this.selectedIdsBS.next([]);
    }

    public search(source: T[], keywords: string, searchAnyTerm = true): T[] {
        const fuse = new Fuse(source, {
            threshold: 0.2,
            minMatchCharLength: 1,
            useExtendedSearch: true,
            findAllMatches: true,
            ignoreLocation: true,
            includeScore: true,
            includeMatches: true,
            keys: this.configuration.searchKeys
        });

        let arrangedKeywords = keywords;
        if (searchAnyTerm) arrangedKeywords = arrangedKeywords.replace(/ /g, "|");

        const searchResults = _(fuse.search(arrangedKeywords)).orderBy(x => x.score).value();

        // For search analysis purpose 
        /*console.info(searchResults.map(x => {
            return {
                score: x.score,
                matches: (x.matches||[]).map(x => {
                    return {
                        value: x.value,
                        found: x.indices.map(idx => x.value?.substring(idx[0], idx[1]+1))
                    }
                }),
                item: x.item
            }
        }));*/

        return searchResults.filter(x => x.score! < 0.5).map(x => x.item);
    }
}

interface Entity {
    readonly id: string;
}

interface DataServiceConfiguration<T> {
    searchKeys: Array<Fuse.FuseOptionKey<T>>;
    filters: Filter<T>[],
    sortItems?: SortItem<T>[]
}