<button mat-icon-button [mdePopoverTriggerFor]="notificationPopover" mdePopoverTriggerOn="click"
    mdePopoverArrowWidth="0" matTooltip="Notifications" i18n-matTooltip aria-label="Notifications" i18n-aria-label>
    @if (notRead > 0) {
        <mat-icon class="material-symbols-outlined" [matBadge]="notRead" matBadgeSize="small" matBadgeColor="warn">notifications_active</mat-icon>
    }
    @if (notRead === 0) {
        <mat-icon class="material-symbols-outlined">notifications</mat-icon>
    }
</button>
<mde-popover #notificationPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
    <div class="mat-elevation-z4 qo-scrollbar bg-white dark:bg-qo-dark-800 dark:text-white rounded-md min-w-70 max-h-70vh w-98p sm:w-112">
        @for (notification of dataService.$datasource|async; track $index) {
            @switch (notification.type) {
                @case (UserNotificationType.EVENT_APPLICATION_ACCEPTED) {
                    <a class="flex p-2 color-inherit border-b border-qo-divider dark:border-gray-700 last:border-0" [ngClass]="{'bg-qo-primary/20 font-medium' : !notification.read}"
                        [routerLink]="NavigationService.EventRoutes.Teammate.Home(notification.event_id!)" matRipple>
                        <div class="flex bg-qo-light dark:bg-qo-dark-600  rounded-md h-12 overflow-hidden mr-2 w-12">
                            @if (notification.thumbnail_asset_key) {
                                <img class="block h-full w-full object-cover" [src]="notification.thumbnail_asset_key|toAssetUrl">
                            }
                            @if (!notification.thumbnail_asset_key) {
                                <mat-icon class="material-symbols-outlined m-auto">notifications</mat-icon>
                            }
                        </div>
                        <div >
                            <span i18n>Your application has been accepted</span>
                            <br /><em class="text-sm not-italic opacity-60">{{notification.timestamp|moment:"lll"}}</em>
                        </div>
                    </a>
                }
                @case (UserNotificationType.EVENT_APPLICATION_PENDING) {
                    <a class="flex p-2 color-inherit border-b border-qo-divider dark:border-gray-700 last:border-0" [ngClass]="{'bg-qo-primary/20 font-medium' : !notification.read}"
                        [routerLink]="NavigationService.EventRoutes.Teammate.Home(notification.event_id!)" matRipple>
                        <div class="flex bg-qo-light dark:bg-qo-dark-600  rounded-md h-12 overflow-hidden mr-2 w-12">
                            @if (notification.thumbnail_asset_key) {
                                <img class="block h-full w-full object-cover" [src]="notification.thumbnail_asset_key|toAssetUrl">
                            }
                            @if (!notification.thumbnail_asset_key) {
                                <mat-icon class="material-symbols-outlined m-auto">notifications</mat-icon>
                            }
                        </div>
                        <div >
                            <span i18n>Your application is pending</span>
                            <br /><em class="text-sm not-italic opacity-60">{{notification.timestamp|moment:"lll"}}</em>
                        </div>
                    </a>
                }
                @case (UserNotificationType.EVENT_APPLICATION_WAITING_LIST) {
                    <a class="flex p-2 color-inherit border-b border-qo-divider dark:border-gray-700 last:border-0" [ngClass]="{'bg-qo-primary/20 font-medium' : !notification.read}"
                        [routerLink]="NavigationService.EventRoutes.Teammate.Home(notification.event_id!)" matRipple>
                        <div class="flex bg-qo-light dark:bg-qo-dark-600  rounded-md h-12 overflow-hidden mr-2 w-12">
                            @if (notification.thumbnail_asset_key) {
                                <img class="block h-full w-full object-cover" [src]="notification.thumbnail_asset_key|toAssetUrl">
                            }
                            @if (!notification.thumbnail_asset_key) {
                                <mat-icon class="material-symbols-outlined m-auto">notifications</mat-icon>
                            }
                        </div>
                        <div >
                            <span i18n>Your application is on waiting list</span>
                            <br /><em class="text-sm not-italic opacity-60">{{notification.timestamp|moment:"lll"}}</em>
                        </div>
                    </a>
                }
                @case (UserNotificationType.EVENT_APPLICATION_CANCELED) {
                    <a class="flex p-2 color-inherit border-b border-qo-divider dark:border-gray-700 last:border-0" [ngClass]="{'bg-qo-primary/20 font-medium' : !notification.read}"
                        [routerLink]="NavigationService.EventRoutes.Teammate.Home(notification.event_id!)" matRipple>
                        <div class="flex bg-qo-light dark:bg-qo-dark-600  rounded-md h-12 overflow-hidden mr-2 w-12">
                            @if (notification.thumbnail_asset_key) {
                                <img class="block h-full w-full object-cover" [src]="notification.thumbnail_asset_key|toAssetUrl">
                            }
                            @if (!notification.thumbnail_asset_key) {
                                <mat-icon class="material-symbols-outlined m-auto">notifications</mat-icon>
                            }
                        </div>
                        <div >
                            <span i18n>Your application has been canceled</span>
                            <br /><em class="text-sm not-italic opacity-60">{{notification.timestamp|moment:"lll"}}</em>
                        </div>
                    </a>
                }
                @case (UserNotificationType.EVENT_APPLICATION_DECLINED) {
                    <a class="flex p-2 color-inherit border-b border-qo-divider dark:border-gray-700 last:border-0" [ngClass]="{'bg-qo-primary/20 font-medium' : !notification.read}"
                        [routerLink]="NavigationService.EventRoutes.Teammate.Home(notification.event_id!)" matRipple>
                        <div class="flex bg-qo-light dark:bg-qo-dark-600  rounded-md h-12 overflow-hidden mr-2 w-12">
                            @if (notification.thumbnail_asset_key) {
                                <img class="block h-full w-full object-cover" [src]="notification.thumbnail_asset_key|toAssetUrl">
                            }
                            @if (!notification.thumbnail_asset_key) {
                                <mat-icon class="material-symbols-outlined m-auto">notifications</mat-icon>
                            }
                        </div>
                        <div >
                            <span i18n>Your application has been declined</span>
                            <br /><em class="text-sm not-italic opacity-60">{{notification.timestamp|moment:"lll"}}</em>
                        </div>
                    </a>
                }
                @case (UserNotificationType.EVENT_APPLICATION_DELETED) {
                    <div class="flex p-2 color-inherit border-b border-qo-divider dark:border-gray-700 last:border-0" [ngClass]="{'bg-qo-primary/20 font-medium' : !notification.read}">
                        <div class="flex bg-qo-light dark:bg-qo-dark-600  rounded-md h-12 overflow-hidden mr-2 w-12">
                            @if (notification.thumbnail_asset_key) {
                                <img class="block h-full w-full object-cover" [src]="notification.thumbnail_asset_key|toAssetUrl">
                            }
                            @if (!notification.thumbnail_asset_key) {
                                <mat-icon class="material-symbols-outlined m-auto">notifications</mat-icon>
                            }
                        </div>
                        <div >
                            <span i18n>Your application has been deleted</span>
                            <br /><em class="text-sm not-italic opacity-60">{{notification.timestamp|moment:"lll"}}</em>
                        </div>
                    </div>
                }
                @case (UserNotificationType.EVENT_PLANNING_UPDATED) {
                    <a class="flex p-2 color-inherit border-b border-qo-divider dark:border-gray-700 last:border-0" [ngClass]="{'bg-qo-primary/20 font-medium' : !notification.read}"
                        [routerLink]="NavigationService.EventRoutes.Teammate.Activities(notification.event_id!)" matRipple>
                        <div class="flex bg-qo-light dark:bg-qo-dark-600  rounded-md h-12 overflow-hidden mr-2 w-12">
                            @if (notification.thumbnail_asset_key) {
                                <img class="block h-full w-full object-cover" [src]="notification.thumbnail_asset_key|toAssetUrl">
                            }
                            @if (!notification.thumbnail_asset_key) {
                                <mat-icon class="material-symbols-outlined m-auto">notifications</mat-icon>
                            }
                        </div>
                        <div >
                            <span i18n>Your schedule has been updated</span>
                            <br /><em class="text-sm not-italic opacity-60">{{notification.timestamp|moment:"lll"}}</em>
                        </div>
                    </a>
                }
            }
        }

        @if ((dataService.$datasource|async)?.length == 0) {
            <div>
                <p class="font-medium text-center m-0 py-8">Aucune notification</p>
            </div>
        }

    </div>
</mde-popover>

