import { Component } from "@angular/core";
import { MenuMode, NavigationService } from "src/app/shared/services/navigation-service/navigation.service";

@Component({
    templateUrl: './event-unavailable.component.html'
})
export class EventUnavailableComponent {
    NavigationService = NavigationService;
    
    constructor(navigationService: NavigationService) {
        navigationService.setMenu(MenuMode.EMPTY);
    }
}