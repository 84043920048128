export enum EventPermission {
    ADMIN_ACCESS = "ADMIN_ACCESS",
    ADMIN_COURSES_READ = "ADMIN_COURSES_READ",
    ADMIN_COURSES_WRITE = "ADMIN_COURSES_WRITE",
    ADMIN_EVENT_READ = "ADMIN_EVENT_READ",
    ADMIN_APPLICATION_WRITE = "ADMIN_APPLICATION_WRITE",
    ADMIN_EVENT_WRITE = "ADMIN_EVENT_WRITE",
    ADMIN_LICENSES_WRITE = "ADMIN_LICENSES_WRITE",
    ADMIN_LOCALIZATION_WRITE = "ADMIN_LOCALIZATION_WRITE",
    ADMIN_MAP_READ = "ADMIN_MAP_READ",
    ADMIN_MAP_WRITE = "ADMIN_MAP_WRITE",
    ADMIN_MATERIAL_READ = "ADMIN_MATERIAL_READ",
    ADMIN_MATERIAL_WRITE = "ADMIN_MATERIAL_WRITE",
    ADMIN_MATERIAL_OPERATIONS_WRITE = "ADMIN_MATERIAL_OPERATIONS_WRITE",
    ADMIN_MEALS_READ = "ADMIN_MEALS_READ",
    ADMIN_MEALS_WRITE = "ADMIN_MEALS_WRITE",
    ADMIN_MEALS_OPERATIONS_WRITE = "ADMIN_MEALS_OPERATIONS_WRITE",
    ADMIN_MEMBERSHIP_WRITE = "ADMIN_MEMBERSHIP_WRITE",
    ADMIN_MESSAGES_READ = "ADMIN_MESSAGES_READ",
    ADMIN_MESSAGES_WRITE = "ADMIN_MESSAGES_WRITE",
    ADMIN_PLANNING_WRITE = "ADMIN_PLANNING_WRITE",
    ADMIN_PLANNING_WRITE_LIMITED = "ADMIN_PLANNING_WRITE_LIMITED",
    ADMIN_PLANNING_OPERATIONS_WRITE = "ADMIN_PLANNING_OPERATIONS_WRITE",
    ADMIN_SMS_WRITE = "ADMIN_SMS_WRITE",
    ADMIN_TEAMMATE_WRITE = "ADMIN_TEAMMATE_WRITE",
    TEAMMATE_ACCESS = "TEAMMATE_ACCESS",
    TEAMMATE_MAP_READ = "TEAMMATE_MAP_READ",
    TEAMMATE_COURSES_READ = "TEAMMATE_COURSES_READ",
    TEAMMATE_COURSES_WRITE = "TEAMMATE_COURSES_WRITE",
    TEAMMATE_DOCUMENT_READ = "TEAMMATE_DOCUMENT_READ",
    TEAMMATE_MESSAGES_READ = "TEAMMATE_MESSAGES_READ",
    TEAMMATE_MESSAGES_WRITE = "TEAMMATE_MESSAGES_WRITE",
    TEAMMATE_PLANNING_READ = "TEAMMATE_PLANNING_READ",
    TEAMMATE_PROFILE_WITHDRAW_APPLICATION = "TEAMMATE_PROFILE_WITHDRAW_APPLICATION",
    TEAMMATE_PROFILE_WRITE = "TEAMMATE_PROFILE_WRITE",
    TEAMMATE_FRIEND_GROUPS_ACCESS = "TEAMMATE_FRIEND_GROUPS_ACCESS",
    TEAMMATE_MEALS_READ = "TEAMMATE_MEALS_READ"
};